@import './reset.css';
@import './nprogress.min.css';


:root {
  /* globlas */
  --black: #000;
  --text-primary: #000;
  --bg-secondary:#fff;
  --orange-primary: orange;
	--navigation_action: red;
	--picture-aspect-ratio: 4/2.8;
  /* header*/
	--font-regular:'Roboto Bold', sans-serif;
	--nav-bar-height: 5.6rem;
	/* contact */
	--contact-block: 572px;
	--padding-container: 2rem;
	
}

@keyframes temporalFade{
	0%{opacity:0;}
	50%{opacity:1}
	90%{opacity:1}
	100%{opacity:0;}
}

video::-internal-media-controls-overlay-cast-button {
	display: none;
}
button {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
/*
html,body{
	overflow-x:hidden;
	overflow-y:visible;
} */
body{font-size:16px; min-height: 100vh; width:100%; font-family: var(--font-regular); overflow-x: hidden; overflow-y: auto; }
html{font-size:16px;-ms-overflow-style:scrollbar;-webkit-tap-highlight-color:transparent; overflow-x: hidden; overflow-y: auto}
h1 { font-size: 1.5rem; line-height: 1.5rem }
h2 { font-size: 1.4rem; line-height: 1.4rem }
h3 { font-size: 1.3rem; line-height: 1.3rem }
h4 { font-size: 1.2rem; line-height: 1.2rem }
h5 { font-size: 1.1rem; line-height: 1.1rem }
h6 { font-size: 1.0rem; line-height: 1.0rem }
p { font-size: 1.0rem; line-height: 1.3rem }
span { font-size: 1.0rem; line-height: 1.0rem }
ul, li { font-size: 1.0rem; line-height: 1.0rem }
p {margin: 0px;}


.textContainer h1 {  margin-bottom: 18px; }
.textContainer h2 {  margin-bottom: 17px;}
.textContainer h3 { margin-bottom: 16.5px;}
.textContainer h4 { margin-bottom: 16px; }
.textContainer h5 {margin-bottom: 14px;}
.textContainer h6 { margin-bottom: 12px;}
.textContainer span, .textContainer ul, .textContainer li, .textContainer p {margin-bottom: 10px;}


a:hover {text-decoration:none;}

.container{ max-width:1430px; width:100%; margin:0 auto;overflow:hidden}
.row{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:0px;margin-left:0px;}
.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9{position:relative;width:100%;min-height:1px}
.col-xs{-webkit-flex-basis:0;-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;max-width:100%;}
.col-xs-auto{-webkit-box-flex:0;-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;width:auto}div[class^="col"]{padding:10px;}div.col-sm-12{padding:0px;}

.col-xs-1{-webkit-box-flex:0;-webkit-flex:0 0 8.333333%;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
.col-xs-2{-webkit-box-flex:0;-webkit-flex:0 0 16.666667%;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
.col-xs-3{-webkit-box-flex:0;-webkit-flex:0 0 25%;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
.col-xs-4{-webkit-box-flex:0;-webkit-flex:0 0 33.333333%;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
.col-xs-5{-webkit-box-flex:0;-webkit-flex:0 0 41.666667%;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
.col-xs-6{-webkit-box-flex:0;-webkit-flex:0 0 50%;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
.col-xs-7{-webkit-box-flex:0;-webkit-flex:0 0 58.333333%;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
.col-xs-8{-webkit-box-flex:0;-webkit-flex:0 0 66.666667%;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
.col-xs-9{-webkit-box-flex:0;-webkit-flex:0 0 75%;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
.col-xs-10{-webkit-box-flex:0;-webkit-flex:0 0 83.333333%;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
.col-xs-11{-webkit-box-flex:0;-webkit-flex:0 0 91.666667%;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
.col-xs-12{-webkit-box-flex:0;-webkit-flex:0 0 100%;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}

@media (max-width:767px){
	.container{width: 100%}
	div[class^="col-xs"]{padding-right:10px;padding-left:10px;}
}

/*media queris sm (768px)*/
@media (min-width:768px){
	.col-sm{-webkit-flex-basis:0;-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;max-width:100%;display:inline-block;}
	.col-sm-auto{-webkit-box-flex:0;-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;width:auto;display:inline-block;}
	.col-sm-1{-webkit-box-flex:0;-webkit-flex:0 0 8.333333%;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;display:inline-block;}
	.col-sm-2{-webkit-box-flex:0;-webkit-flex:0 0 16.666667%;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;display:inline-block;}
	.col-sm-3{-webkit-box-flex:0;-webkit-flex:0 0 25%;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;display:inline-block;}
	.col-sm-4{-webkit-box-flex:0;-webkit-flex:0 0 33.333333%;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;display:inline-block;}
	.col-sm-5{-webkit-box-flex:0;-webkit-flex:0 0 41.666667%;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;display:inline-block;}
	.col-sm-6{-webkit-box-flex:0;-webkit-flex:0 0 50%;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;display:inline-block;}
	.col-sm-7{-webkit-box-flex:0;-webkit-flex:0 0 58.333333%;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;display:inline-block;}
	.col-sm-8{-webkit-box-flex:0;-webkit-flex:0 0 66.666667%;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;display:inline-block;}
	.col-sm-9{-webkit-box-flex:0;-webkit-flex:0 0 75%;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;display:inline-block;}
	.col-sm-10{-webkit-box-flex:0;-webkit-flex:0 0 83.333333%;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;display:inline-block;}
	.col-sm-11{-webkit-box-flex:0;-webkit-flex:0 0 91.666667%;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;display:inline-block;}
	.col-sm-12{-webkit-box-flex:0;-webkit-flex:0 0 100%;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;display:inline-block;}
}

	.margin0{margin: 0px !important;}/*Utilizar en rows*/
	.padding0{padding: 0px !important;}/*Utilizar en cols*/

	.tituloSeccion{
		font-size: 1.65rem;
		color: #0b3c3a;
		margin-bottom:2rem;
	}

	
	.regular{
		font-family: var(--font-regular);
		font-weight: 400;
	}
	
	.bold{
		font-family: var(--font-regular);
		font-weight: 700;
	}
	.extrabold{
		font-family: var(--font-regular);
		font-weight: 900;
	}
	
	.pagina{
		padding: 10px;
	}



	@media (max-width: 767px) {
		.hidden-xs {display: none !important;}
	}
	@media screen and (min-width: 768px) {
		.visible-xs{display: none !important;}
	}
	
	@media (max-width:868px){
		html, body{font-size:15px}
	}

	@media (max-width:658px){
		html, body{font-size:14.5px}
	}

	@media (max-width:590px){
		html, body{
			padding-bottom:2rem;
		}
	}

	@media (max-width:568px){
		html, body{font-size:14px}
	}

	@media (max-width:530px){
		html, body{font-size:13px}
	}

	@media (max-width:500px){
		html, body{font-size:12px}
	}


	video::-internal-media-controls-overlay-cast-button {
		display: none;
	}